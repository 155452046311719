<template>
    <div>
        <Header/>
        <!-- A Few Words About Our Bank-->
        <section class="section section-lg bg-gray-100 bg-light-figure" style="padding-top:200px">
            <div class="container">
                <div class="row row-20 justify-content-center justify-content-lg-between">
                    <div class="col-md-10 col-lg-6 wow fadeIn"><img class="out-of-container" src="@/assets/images/main-slide-2.png" alt="" width="691" height="452"/>
                    </div>
                    <div class="col-md-10 col-lg-6">
                        <div class="block-lg text-left">
                        <h2>手势识别</h2>
                        <!-- <p class="sup pr-xl-5">We're a world-class web hosting company who offers a range of hosting services, including web hosting, reseller hosting,</p> -->
                        </div>
                        <div class="text-block-2" style="margin-top: 50px">
                        <p>检测手部位置，识别手势含义。通过手势识别，用户可以使用简单的手势来控制或与设备交互，让计算机理解人类的行为。</p>
                        <div class="progress-linear-wrap">
                            
                        </div><a class="button button-lg button-secondary" href="/#about">查看更多</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="function">
            <div class="container" style="max-width:1140px">
                <div class="row">
                    <div class="col-lg-12 text-center">
                        <h2 class="section-heading text-uppercase">{{ $t('vtype.demo') }}</h2>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6 col-sm-12" id="slide">
                        <div class="div_demo">
                            <img id="img_demo" class="img_demo" src="@/assets/images/demo/gesture/1.jpeg">
                            <canvas id="landmark_canvas" :width="clientWidth" :height="clientWidth"></canvas>
                            <div class="bg_img_upload"></div>
                            <div class="img_upload">
                                <input type="text" id="textId">
                                <a href="javascript:;" class="file">{{ $t('vtype.file') }}
                                    <input accept="image/png,image/jpeg,image/jpg" class="idimg" name="file" type="file"
                                           v-on:change="uploadIMG($event)">
                                </a>
                            </div>
                        </div>
                        <div id="slide-list" ref="slideList">
                            <img :style="thumbnail" src="@/assets/images/demo/gesture/1.jpeg" @click="localRecognition(1)">
                            <img :style="thumbnail" src="@/assets/images/demo/gesture/2.jpeg" @click="localRecognition(2)">
                            <img :style="thumbnail" src="@/assets/images/demo/gesture/3.jpeg" @click="localRecognition(3)">
                            <img :style="thumbnail" src="@/assets/images/demo/gesture/4.jpeg" @click="localRecognition(4)">
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12 panel" id="panel" ref="panel">
                        <ul class="nav nav-tabs mt-3" id="myTab">
                            <li class="col-md-6 mb-2" @click="clickResponse(0)">分析结果</li>
                            <li class="col-md-6 mb-2" @click="clickResponse(2)">Response</li>
                        </ul>

                        <div class="tab-content">
                            <div class="tab-pane active" style="padding-left:30px; padding-top:30px" id="result"></div>
                            <div class="tab-pane active" style="text-align:left; padding-left:30px; padding-top:30px" id="response"></div>
                        </div>
                    </div>

                </div>
            </div>
        </section>

        <!-- Portfolio Grid -->
        <section class="bg-light" id="portfolio2">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 text-center">
                        <h2 class="section-heading text-uppercase">{{ $t('vtype.scene') }}</h2>
                    </div>
                </div>
                <div class="row text-center">
                    <div class="col-md-1 col-sm-12 portfolio-item">
                    </div>
                    <div class="col-md-5 col-sm-12 portfolio-item">
                        <img class="img-fluid" src="@/assets/images/gesture/3.webp" alt="">
                        <div class="portfolio-caption mt-3">
                            <h5>虚拟现实</h5>
                        </div>
                    </div>
                    <div class="col-md-5 col-sm-12 portfolio-item">
                        <img class="img-fluid" src="@/assets/images/gesture/1.jpeg" alt="">
                        <div class="portfolio-caption mt-3">
                            <h5>视频互动</h5>
                        </div>
                    </div>

                </div>
            </div>
        </section>
        <!-- <section id="services">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 text-center">
                        <h2 class="section-heading">接入形式</h2>
                        <hr class="my-4">
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-lg-3 col-md-6 text-center">
                        <div class="service-box mt-5 mx-auto">
                            <span class="fa fa-4x fa-cogs text-primary mb-3 sr-icon-1"></span>
                            <h4 class="mb-3">{{ $t('vtype.advantage_title1') }}</h4>
                            <p class="text-muted mb-0">{{ $t('vtype.advantage_desc1') }}</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 text-center">
                        <div class="service-box mt-5 mx-auto">
                            <span class="fa fa-4x fa-briefcase text-primary mb-3 sr-icon-2"></span>
                            <h4 class="mb-3">{{ $t('vtype.advantage_title2') }}</h4>
                            <p class="text-muted mb-0">{{ $t('vtype.advantage_desc2') }}</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 text-center">
                        <div class="service-box mt-5 mx-auto">
                            <span class="fa fa-4x fa-globe text-primary mb-3 sr-icon-3"></span>
                            <h4 class="mb-3">{{ $t('vtype.advantage_title3') }}</h4>
                            <p class="text-muted mb-0">{{ $t('vtype.advantage_desc3') }}</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 text-center">
                        <div class="service-box mt-5 mx-auto">
                            <span class="fa fa-4x fa-car text-primary mb-3 sr-icon-4"></span>
                            <h4 class="mb-3">{{ $t('vtype.advantage_title4') }}</h4>
                            <p class="text-muted mb-0">{{ $t('vtype.advantage_desc4') }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section> -->
        <Bottom/>
    </div>
</template>

<script>
    // @ is an alias to /src
    import Header from '@/components/Header.vue'
    import Bottom from "@/components/Bottom";
    import axios from 'axios';
    import initFunc from '@/assets/js/script'

    export default {
        name: 'Home',
        components: {
            Bottom, Header
        },
        metaInfo: {
            title: "智云视图-专注移动端高效视觉算法研发"
        },
        mounted() {
            this.clientWidth = this.$refs.slideList.clientWidth;
            this.localRecognition(1)
            this.clickResponse(0)
            initFunc()
        },
        data() {
            return {
                clientWidth: 760,
                imageWidth: 760,
                imageHeight: 475
            }
        },
        computed: {
            thumbnail() {
                return {
                    'padding-left': '10px',
                    width: '25%',
                    height: (this.clientWidth * 0.2) + 'px',
                    'object-fit': 'fill'
                }
            }
        },
        methods: {
            animate(obj, json) {
                //关闭上一个定时器
                clearInterval(obj.timer)

                obj.timer = setInterval(() => {
                    //规定动画是否开启
                    let flag = true;
                    //每次走的距离  距离 = 目标位置 - 当前位置
                    for (let arrt in json) {
                        let step = (json[arrt] - parseInt(this.getStyle(obj, arrt))) / 10;
                        // 距离取证
                        step = step > 0 ? Math.ceil(step) : Math.floor(step)
                        //让盒子动起来
                        obj.style[arrt] = parseInt(this.getStyle(obj, arrt)) + step + "px";
                        //

                        if (parseInt(this.getStyle(obj, arrt)) != json[arrt]) {
                            flag = false;
                        }
                    }
                    if (flag) {
                        clearInterval(obj.timer);
                    }

                }, 20)
            },
            getStyle(obj, arrt) {
                //ie垃圾兼容
                return obj.currentStyle ? obj.currentStyle[arrt] : getComputedStyle(obj, null)[arrt]
            },
            clickResponse(index) {
                let result = document.getElementById("result")
                let response = document.getElementById("response")
                if (index !== 0) {
                    result.style.display="none";
                    response.style.display="";
                } else {
                    result.style.display="";
                    response.style.display="none";
                }
            },
            drawPoint(allPoints) {
                let c = document.getElementById("landmark_canvas");
                let ctx = c.getContext("2d");
                ctx.clearRect(0,0,c.width,c.height);
                // 设置绘制颜色
                ctx.strokeStyle = "#00FF00";
                ctx.lineWidth = 2;
                for (let points of allPoints) {
                    ctx.beginPath();
                    points = [[points[0], points[1]], [points[0], points[3]], [points[2], points[3]], [points[2], points[1]]]
                    for(let i = 0; i < points.length; i++) {
                        let point = points[i]
                        if (this.imageHeight / this.imageWidth > 1) {
                            let scale = this.imageHeight / (this.clientWidth)
                            point[0] = (this.clientWidth - (this.imageWidth / scale)) / 2 + point[0] / scale
                            point[1] = point[1] / scale
                        } else {
                            let scale = this.imageWidth / this.clientWidth
                            point[1] = (this.clientWidth - (this.imageHeight / scale)) / 2 + point[1] / scale
                            point[0] = point[0] / scale
                        }
                        ctx.lineTo(point[0],point[1]);
                    }
                    ctx.closePath();
                    ctx.stroke();
                }
            },
            uploadIMG(e) {
                let files = e.target.files || e.dataTransfer.files
                let id = e.target.id
                if (!files.length) return
                this.picavalue = files[0]
                // document.getElementById('textId').value = this.picavalue.value
                if (this.picavalue.size / 1024 > 2048) {
                    alert("请上传小于2M的图片")
                } else {
                    this.imgPreview(this.picavalue, id)
                }
            },
            //获取图片
            imgPreview(file, id) {
                let self = this
                //判断支不支持FileReader
                if (!file || !window.FileReader) return false
                if (/^image/.test(file.type)) {
                    //创建一个reader
                    let reader = new FileReader()
                    //将图片转成base64格式
                    reader.readAsDataURL(file)
                    //读取成功后的回调
                    reader.onloadend = function () {
                        let result = this.result
                        let image_demo = document.getElementById("img_demo")
                        image_demo.src = result
                        if (file.size / 1024 < 300) {
                            let img = new Image()
                            img.src = result
                            img.onload = function () {
                                self.imageWidth = img.width
                                self.imageHeight = img.height
                            }
                            result = result.replace(/^data:image\/\w+;base64,/, "");
                            self.uploadImg(result, id)
                        } else {
                            let img = new Image()
                            img.src = result
                            // console.log('********未压缩前的图片大小********')
                            // console.log(result.length / 1024)
                            img.onload = function () {
                                self.imageWidth = img.width
                                self.imageHeight = img.height
                                let data = self.compress(img, 0.3)
                                data = data.replace(/^data:image\/\w+;base64,/, "");
                                self.uploadImg(data, id)
                            }
                        }
                    }
                }
            },
            // 压缩图片
            compress(img, size) {
                let canvas = document.createElement('canvas')
                let ctx = canvas.getContext('2d')
                // let initSize = img.src.length
                let width = img.width
                let height = img.height
                canvas.width = width
                canvas.height = height
                // 铺底色
                ctx.fillStyle = '#fff'
                ctx.fillRect(0, 0, canvas.width, canvas.height)
                ctx.drawImage(img, 0, 0, width, height)
                //进行最小压缩
                let ndata = canvas.toDataURL('image/jpeg', size)
                // console.log('*******压缩后的图片大小*******')
                // console.log(ndata)
                // console.log(ndata.length / 1024)
                return ndata
            },
            uploadImg(base64) {
                this.drawPoint([])
                let resultDiv = document.getElementById("result")
                resultDiv.innerHTML = ""
                axios.post('/api/v1/handRecgBase64', {
                    source: base64
                })
                    .then((response) => {
                        // console.log(response);
                        let responseDiv = document.getElementById("response")
                        let result = response.data.res
                        // {"code": 5, "msg": "successfully", "cid": 1, "res": [[61, 74, 105, 155], ["spread", 0.9987785220146179], [286, 89, 333, 170], ["spread", 0.9990679621696472]]}
                        // ["heart","spread","index","fist","thumb","other"]
                        // 比心，手部张开，竖食指，握拳，点赞，其他
                        // if (result != null) {
                        //     resultDiv.innerHTML = '<img src="data:image/png;base64,' + result + '"/>'
                        //     responseDiv.innerHTML = "<p>{</p><p>  handimg: \"图片的Base64编码\"  </p><p>}</p>".replace(/ /ig, '&nbsp;')
                        // }
                        let gestures = {"heart": "比心","spread": "手部张开","index": "竖食指","fist": "握拳","thumb": "点赞","other": "其他"}
                        let points = []
                        if (result != null) {
                            for (let i = 0; i < result.length; i++) {
                                if (i % 2 == 0) {
                                    points[i / 2] = result[i]
                                } else {
                                    resultDiv.innerHTML += "<p>手势" + (i + 1) / 2 + "：" + gestures[result[i][0]] + " (" + result[i][0] + ")</p><p>置信度：" + result[i][1] + "</p>"
                                }
                            }
                            responseDiv.innerHTML = JSON.stringify(response.data, null, 4)
                        }
                        this.drawPoint(points)
                    })
            },
            localRecognition(index) {
                let img_demo = document.getElementById('img_demo');
                let img = new Image()
                img.src = require("../assets/images/demo/gesture/" + index + ".jpeg")
                img_demo.src = require("../assets/images/demo/gesture/" + index + ".jpeg")
                img.onload = () => {
                    this.imageWidth = img.width
                    this.imageHeight = img.height
                    let data = this.compress(img, 0.3)
                    data = data.replace(/^data:image\/\w+;base64,/, "");
                    this.uploadImg(data)
                }
                // img.onerror = function (error) {
                //     console.log(error)
                // }
            },
        }
    }

</script>

<style scoped>
    header.masthead {
        padding-top: 10rem;
        padding-bottom: calc(10rem - 56px);
        background-image: url("../assets/images/vehicletype/car_long.jpg");
        background-position: center center;
        background-size: cover;
    }

    header.masthead hr {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    header.masthead h1 {
        font-size: 2rem;
    }

    header.masthead p {
        font-weight: 300;
    }

    @media (min-width: 768px) {
        header.masthead p {
            font-size: 1.15rem;
        }
    }

    @media (min-width: 992px) {
        header.masthead {
            height: 50vh;
            min-height: 450px;
            padding-top: 0;
            padding-bottom: 0;
        }
        header.masthead h1 {
            font-size: 3rem;
        }
    }

    @media (min-width: 1200px) {
        header.masthead h1 {
            font-size: 4rem;
        }
    }

    .service-box {
        max-width: 400px;
    }

    #portfolio .portfolio-item {
        right: 0;
        margin: 0 0 15px;
        text-align: center;
    }

    #portfolio .portfolio-item .portfolio-link {
        position: relative;
        display: block;
        max-width: 400px;
        margin: 0 auto;
        cursor: pointer;
        background: #FFFFFF;
    }

    #portfolio .portfolio-item .portfolio-link .portfolio-hover {
        position: absolute;
        width: 100%;
        height: 100%;
        -webkit-transition: all ease 0.5s;
        transition: all ease 0.5s;
        opacity: 0;
        background: rgba(93, 146, 227, 0.8);
    }

    #portfolio .portfolio-item .portfolio-link .portfolio-hover:hover {
        opacity: 1;
    }

    #portfolio .portfolio-item .portfolio-link .portfolio-hover .portfolio-hover-content {
        font-size: 20px;
        position: absolute;
        top: 50%;
        width: 100%;
        height: 20px;
        margin-top: -12px;
        text-align: center;
        color: white;
    }

    #portfolio .portfolio-item .portfolio-link .portfolio-hover .portfolio-hover-content i {
        margin-top: -12px;
    }

    #portfolio .portfolio-item .portfolio-link .portfolio-hover .portfolio-hover-content h3,
    #portfolio .portfolio-item .portfolio-link .portfolio-hover .portfolio-hover-content h4 {
        margin: 0;
    }

    #portfolio .portfolio-item .portfolio-caption {
        max-width: 400px;
        margin: 0 auto;
        padding: 25px;
        text-align: center;
    }

    #portfolio .portfolio-item .portfolio-caption h4 {
        margin: 0;
        text-transform: none;
    }

    #portfolio .portfolio-item .portfolio-caption p {
        font-size: 16px;
        font-style: italic;
        margin: 0;
        font-family: 'Droid Serif', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    }

    #portfolio * {
        z-index: 2;
    }

    @media (min-width: 767px) {
        #portfolio .portfolio-item {
            margin: 0 0 30px;
        }
    }

    .text-primary {
        color: #5D92E3 !important;
    }

    section {
        padding: 3rem 0;
    }

    .panel{
        height: auto;
        background-color: #F3F3F3;
        padding: 0;
    }
    .panel-title{
        width: 100%;
        height: 40px;
        font:400 18px/50px '微软雅黑';
        color: #333;
        cursor: pointer;
        margin-left: 15px;
    }
    .collapase{
        width: 100%;
        height: 0;
        background: #F3F3F3;
        overflow: scroll;
        border-bottom:1px solid #DDD;
        white-space: pre-line;
    }
    .collapase p{
        color: #999;
        padding: 20px;
    }

    @keyframes show{
        0%{height: 0;}
        100%{height: 210px;}
    }
    @keyframes show2{
        0%{height: 210px;}
        100%{height: 0px;}
    }

    #slide {
        padding: 0;
        background: #EEE;
    }

    #slide-list {
        margin-top: 10px;
    }

    #slide-list img {
        cursor: pointer;
    }

    .div_demo {
        position: relative;
        width: 100%;
        height: 0;
        padding-top: 100%;
        background: #333333;
    }

    .img_demo {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
        margin: 0 auto;
    }

    .img_upload {
        background: #666;
        position: absolute;
        top: 93%;
        left: 0;
        width: 100%;
        height: 7%;
        opacity: 0.5;
    }

    .file{
        position:relative;
        display:inline-block;
        background:#333;
        border:1px solid #000;
        /*padding:5px 20px;*/
        /*border-radius:4px;*/
        overflow:hidden;
        color:#FFF;
        text-decoration:none;
        text-indent:0;
        line-height:220%;
        height: 100%;
        width: 20%;
        vertical-align:middle;
        text-align: center;
    }
    .file input{
        position:absolute;
        /* 防止在各浏览器中上传按钮的显示不同,给上传按钮设置文字大小 */
        font-size:100px;
        /* 解决IE中无法靠近边缘问题 */
        right:-5px;
        top:-5px;
        /* 设置透明度为0,针对非IE浏览器或高版本IE浏览器*/
        opacity:0;
        /* 设置透明度为0,这里针对低版本IE浏览器 */
        filter:alpha(opacity=0);
        cursor:pointer;
        background: #333333;
        vertical-align:middle;
    }
    .file:hover{
        background:#333;
        border-color:#000;
        color:#000;
        text-decoration:none;
    }
    #textId{
        width:80%;
        height:100%;
        border:1px solid #000000;
        /*border-radius:4px;*/
        vertical-align:top;
        background: #666666;
    }
    #landmark_canvas {
        position:absolute;
        left: 0;
        top: 0;
    }
</style>
